export default [
  {
    title: "Dashboard",
    icon: "UserIcon",
    route: "dashboard",
    permission: "public",
  },

  {
    title: "Cases",
    icon: "HomeIcon",
    route: "cases",
    permission: "access_cases",
  },

  {
    title: "reports",
    icon: "BarChartIcon",
    permission: "public",
    children: [
      {
        title: "Doctors",
        route: "reports-doctors",
        icon: "FileIcon",
        permission: "access_report-doctor",
      },
      {
        title: "Branches",
        route: "reports-branches",
        icon: "FileIcon",
        permission: "access_report-branch",
      },
      {
        title: "Auditors",
        route: "reports-auditors",
        icon: "FileIcon",
        permission: "access_report-auditor",
      },
      {
        title: "Overall",
        route: "reports-over-all",
        icon: "FileIcon",
        permission: "access_report-auditor",
      },
    ],
  },

  {
    header: "Administration",
    permission: "public",
  },

  // {
  //     title: 'Administration',
  //     icon: 'UserPlusIcon',
  //     permission: 'access_users',
  //     children: [

  {
    title: "users",
    route: "users",
    icon: "UsersIcon",
    permission: "access_users",
  },
  {
    title: "roles",
    route: "roles",
    icon: "ClipboardIcon",

    permission: "access_roles",
  },
  {
    title: "Branches",
    icon: "CodepenIcon",
    route: "branches",
    permission: "access_branches",
  },  
  {
    title: "Auto Checks",
    route: "conditions",
    icon: "CheckIcon",

    permission: "access_conditions",
  },
  {
    title: "Collected Data",
    icon: "SettingsIcon",
    //  icon: 'CheckIcon',

    route: "collected-data",
    permission: "access_collected-data",
  },
  {
    title: "Sync Log",
    icon: "FileIcon",
    route: "pullLogs",
    permission: "access_branches",
  },


  //     ],
  // },
  // {
  //     title: 'User Settings',
  //     icon: 'SettingsIcon',
  //     permission: 'access_users',
  //     children: [
  //         {
  //             title: 'logs',
  //             route: 'logs',
  //             permission: 'accessLogs_administration',
  //         },

  //     ],
  // },
];
